<template>
  <div>
    <cui-register-google />
  </div>
</template>
<script>
import CuiRegisterGoogle from '@/components/cleanui/system/Auth/RegisterFromGoogleAccount'
export default {
  components: {
    CuiRegisterGoogle,
  },
}
</script>
