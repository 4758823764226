import apiClient from "@/services/axios";

const getPhoneCode = async () => {
    const res = await apiClient.get(`/api/phone/phone.json`, {});
    const phoneCode = []
    if (res) {
        for (const [country, code] of Object.entries(res.data)) {
            // phoneCode.push({ country, code })
            if (!phoneCode.some(entry => entry.code === code)) {
                phoneCode.push({ country, code });
            }
        }
        phoneCode.sort((a, b) => a.code.localeCompare(b.code));
        return phoneCode;
    } else {
        return null;
    }
}

export {
    getPhoneCode,
}